import { configureStore } from '@reduxjs/toolkit'
import settingReducer from '../components/common/Settings/SettingsSlice'
import advancedChartPageReducer from '../components/pages/AdvancedChart/AdvancedChartPageSlice'
import advancedChartSanadPageReducer from '../components/pages/SanadChart/AdvancedChartSanadPageSlice'
import loginFormReducer from '../components/forms/Login/LoginFormSlice'
import navSettingsReducer from '../components/common/NavSettings/NavSettingsSlice'
import accountChartPageReducer from '../components/pages/AccountChart/AccountChartSlice'

export const store = configureStore({
  reducer: {
    //settings: settingReducer,
    advancedChartPage: advancedChartPageReducer,
    advancedChartSanadPage: advancedChartSanadPageReducer,
    loginForm: loginFormReducer,
    settings: navSettingsReducer,
    accountChartPage: accountChartPageReducer,

  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

