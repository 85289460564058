import { Metadata } from "grpc-web";
import { getCurrentLanguage } from "./Helpers";
import i18n from "./i18n";
import { AuthenticationEndpointClient } from "../repository/dimensions/AuthenticationServiceClientPb";
import { AuthenticateReply, RefreshTokenRequest } from "../repository/dimensions/authentication_pb";

export const ADVANCED_CHART_API_URL = "https://advancedcharting.azurewebsites.net";//"https://localhost:5101";
export const API_URL = "https://dimensionsclientportalProd.azurewebsites.net"//"http://192.168.8.100:5207";//"https://dimensionsclientportal.azurewebsites.net";//"https://localhost:5101";


const client = new AuthenticationEndpointClient(API_URL, null, null);

export interface ApiMessage {
    body: string,
    title: string,
    type: number,
    data: any,
}

export interface APIRequest<T> {
    body: T,
    headers?: {},
    metadata?: any,
}

export interface APIResponse<T> {
    metadata?: any,
    response: T,
}



export const toApiMessage = (res: any): ApiMessage => {
    let type = Number(res.type?.value)
    if (!Number.isNaN(type)) {
        return { body: decodeURIComponent(res.body?.value), title: decodeURIComponent(res.title?.value), type: type } as ApiMessage;
    } else {
        return { data: decodeURIComponent(res?.data ?? ""), body: decodeURIComponent(res.body), title: decodeURIComponent(res.title), type: Number(res.type) } as ApiMessage;
    }
}


export const getCurrentLng = () => {

    var lang = getCurrentLanguage();
    if (lang == 'ar')
        lang += "-LY"
    else
        lang += "-US"

    return lang;
};


export const getHeaders = () => {

    var auth: Record<string, any> = { "Accept-Language": getCurrentLng(), "Timezone": (((new Date()).getTimezoneOffset()) * -1) + ""   };
    try {
        auth = {
            ...auth,
            "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
        }
    } catch {

    }
    return auth;
};


export const refreshSession = async (): Promise<boolean> => {
    try {
        var session = getSession() as AuthenticateReply.AsObject;
        if (session != null) {
            var req = new RefreshTokenRequest();
            req.setSessionid(Number(session.accesstoken?.sessionid))
            if (session.accesstoken?.refreshtoken) {
                req.setRefreshtoken(session.accesstoken?.refreshtoken?.value)
                var r = await client.refreshToken(req, getHeaders() ?? {});
                var response = r.toObject();
                //console.log(response);
                if (response?.authenticate) {
                    saveSession(response?.authenticate)
                    return true;
                }
            }
            return false;

        }
        return false;

    } catch {
        return false;
    }
}

let currentSession: any = localStorage.getItem('sessionDimansions');

export const saveSession = (session: AuthenticateReply.AsObject ) => {
    currentSession = JSON.stringify(session)
    localStorage.setItem('sessionDimansions', currentSession)
};


export const getSession = (): AuthenticateReply.AsObject  => {
    var i = currentSession;
    if (i) {
        var c = JSON.parse(i) as any;
        if (c.accesstoken) {
            return c;
        }
    }
    clearSession();
    throw null;
};
export const getUsername = (): string | undefined => {

    var i = currentSession;
    if (i) {
        var c = JSON.parse(i) as any;
        if (c.username?.value) {
            return c.username?.value;
        }
    }
    throw undefined;
};

export const isLoggedIn = (): boolean => {
    var i = currentSession;
    if (i) {
        var c = JSON.parse(i) as AuthenticateReply.AsObject;
        if (c.accesstoken) {
            return true;
        }
    }
    return false;
};

export const clearSession = () => {
    currentSession = undefined;
    localStorage.removeItem('sessionDimansions');

};



