import { CommandBar, Text, Stack, PrimaryButton, DefaultButton, Link, Modal, IconButton, IIconProps, getTheme, mergeStyleSets, FontWeights, IButtonStyles, Label, TextField, Layer, mergeStyles, AnimationClassNames, setRTL } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import { Settings } from "../../common/Settings/Settings";
import { isDarkModeEnabled, isVisible, setAppTheme, setLang } from "../../common/NavSettings/NavSettingsSlice";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Popup } from "../../common/Popup/Popup";
import { useEffect, useState } from "react";
import { getPage } from "../../../app/Pages";
import { formatDate, getCurrentLanguage, normalizeKey } from "../../../app/Helpers";
import { version } from '../../../charting_library';
import { TVChartContainer } from "../../pages/TVChartContainer";
import { useParams, useSearchParams } from "react-router-dom";
import { ThemeManager } from "../../../app/Themes";
import { AdvancedChartPage } from "../../pages/AdvancedChart/AdvancedChartPage";
import { saveSession } from "../../../app/SarafaAPI";
import { saveSession as DimSave } from "../../../app/Api";

import { AuthenticateReply } from "../../../repository/Sarafa/authentication_pb";
import { AdvancedChartSanadPage } from "../../pages/SanadChart/AdvancedChartSanadPage";
import { NavSettings } from "../../common/NavSettings/NavSettings";
import { AccountChartPage } from "../../pages/AccountChart/AccountChartPage";

const theme = getTheme();


const key = "login"

export const Main: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams();
  // const navigate = useNavigate();

  const [isIframe, setIsIframe] = useState(false);
  const state: { isVisible: boolean, appTheme: string, isDarkModeEnabled: boolean, currentLang: string } = useAppSelector((state) => {
    return { isVisible: state.settings.isVisible, appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, currentLang: state.settings.currentLang };
  })

  const [isTokenLoaded, setIsTokenLoaded] = useState(false);

  const [isAuthService, setIsAuthService] = useState<any>();

  useEffect(() => {
    const auth = searchParams.get('auth');
    const lang = searchParams.get('lang');
    const isDark = searchParams.get('isDark');
    const isIframe = searchParams.get('isIframe');

    if (auth == "1") {
      setIsAuthService("1")
      window.addEventListener("message", message => {
        try {
          var d = JSON.parse(message.data)
          if (d.accesstoken?.sessionid) {
            saveSession(d)
            setIsTokenLoaded(true)
          }
        } catch { }
      });
    }
    if (auth == "3") {
      setIsAuthService("3")
      window.addEventListener("message", message => {
        try {
          var d = JSON.parse(message.data)
          if (d.accesstoken?.sessionid) {
            saveSession(d)
            setIsTokenLoaded(true)
          }
        } catch { }
      });
    }
    if (auth == "2") {
      setIsAuthService("2")
      window.addEventListener("message", message => {
        try {
          var d = JSON.parse(message.data)
          if (d.accesstoken?.sessionid) {
            DimSave(d)
            setIsTokenLoaded(true)
          }
        } catch { }
      });
    }
    if (isIframe != undefined) {
      setIsIframe(isIframe == "1")
    }

    if (isDark) {
      var mode = isDark == "1";
      var t = ThemeManager.getThemeManager().getTheme(state.appTheme, mode)
      dispatch(setAppTheme(t.themeId))
      dispatch(isDarkModeEnabled(mode))
      ThemeManager.getThemeManager().applyTheme(t)
    }
    if (lang) {
      localStorage.setItem("lang", lang);
      dispatch(setLang(lang))
      i18n.changeLanguage(lang)
      setRTL(lang == 'ar');
      //  window.location.replace(window.location.pathname);
    }



  }, [])
  return (
    <Stack verticalFill>

      {isIframe == false ? <NavSettings /> : undefined}
      <Stack verticalFill>

      {isAuthService == "1" ? isTokenLoaded ? <AdvancedChartPage /> : undefined : isAuthService == "2" ? isTokenLoaded ? <AdvancedChartSanadPage /> : undefined : isAuthService == "3" ? isTokenLoaded ? <AccountChartPage /> : undefined : <AdvancedChartSanadPage />}


        {isIframe == false ? <Stack horizontal tokens={{ childrenGap: 20 }} >

          <Stack.Item grow >
            <Text variant="small">{t("copyright", { year: formatDate((new Date()), "YYYY") })}</Text>
            <Link href={"https://devpoint.ly"} target="_blank">{t("devpoint")}</Link>
          </Stack.Item>
          <Stack.Item>
            <IconButton
              iconProps={{ iconName: 'Settings' }}
              title={t("settings")}
              ariaLabel={t("settings")}
              onClick={(ev) => {
                dispatch(isVisible(true))
              }}
            />

          </Stack.Item>
        </Stack> : undefined}
      </Stack>

    </Stack >
  );
}

